class UserService {
    getPublicContent() {
        return new Promise<string>(() => 'ALL'); //axios.get(API_URL + 'all');
    }

    getUserBoard() {
        return new Promise<string>(() => 'USER'); //axios.get(API_URL + 'user', { headers: authHeader() });
    }

    getModeratorBoard() {
        return new Promise<string>(() => 'MOD'); //axios.get(API_URL + 'mod', {headers: authHeader()});
    }

    getAdminBoard() {
        return new Promise<string>(() => 'ADMIN'); //axios.get(API_URL + 'admin', {headers: authHeader()});
    }
}

export default new UserService();