





import {Component, Vue} from "vue-property-decorator";
import userService from "@/service/user.service";

@Component
export default class UserBoard extends Vue {
  private content = "";

  mounted() {
    userService.getUserBoard().then(
        (response) => {
          this.content = response;
        },
        (error) => {
          this.content =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
        }
    );
  }
}
